@import "src/App";

header, main, footer {
  width: 100%;
}

main {
  flex: auto;
  text-align: center;
  padding-top: 2rem;

  .banner-and-slogan-container {
    text-align: left;
    width: 100%;
    display: inline-block;
    padding: 0 2rem;
    margin-bottom: 2rem;
  }

  .outlet-container {
    text-align: left;
    width: 100%;
    display: inline-block;
    padding: 0 5rem;

    .outlet-container-in {
      width: 100%;
      display: flex;

      .search-box-for-desktop {
        margin-right: 1.5rem;
        flex-grow: 2;
        max-width: 312px;
        min-width: 312px;
      }

      .outlet {
        flex-grow: 1;
      }
    }

  }
}

footer {
  margin-top: 2rem;
  background-color: #222222;
  text-align: center;
  color: #CED1D9;
  font-size: 14px;
}

@include media-breakpoint-down(xxl) {
  main {
    padding-top: 1rem;

    .outlet-container {
      padding: 0 1.5rem;
    }

    .banner-and-slogan-container {
      padding: 0 0;
    }
  }
}

@include media-breakpoint-down(xl) {
  main {
    padding-top: 0.5rem;
    .outlet-container {
      padding: 0 1rem;
    }

    .banner-and-slogan-container {
      padding: 0 1rem;
    }
  }
}

@include media-breakpoint-down(lg) {
  main {
    padding-top: 0.5rem;
    .outlet-container {
      padding: 0 1rem;

      .outlet-container-in .search-box-for-desktop {
        max-width: 250px;
        min-width: 250px;
      }
    }

    .banner-and-slogan-container {
      padding: 0 2rem;
    }
  }
}

@include media-breakpoint-down(md) {
  main {
    padding-top: 0.5rem;
    .outlet-container {
      padding: 0 1rem;
    }

    .banner-and-slogan-container {
      padding: 0;
      margin-bottom: 0.6rem;
      .banner {
        display: none;
      }
    }
  }

  footer {
    margin-top: 1rem;
  }
}
