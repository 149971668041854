@import "src/App";

.header-container {
  background-color: #222222;
  display: flex;
  justify-content: center;
  height: 88px;

  .header-in {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;

    .navigation-mobile {
      display: none;
    }

    .logo {
      color: #CED1D9;
      width: 182px;
      height: 100%;
      padding: 18px 25px 16px 5px;
      margin-left: 1.2rem;
    }

    .title {
      color: #CED1D9;
      display: flex;
      align-items: center;
      justify-content: center;

      .title-in {
        font-size: 18px;
        padding: 0 0 0 50px; //originally padding-left was 70px
        border-left: #CED1D9 1px solid;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .navigation {
      color: #CED1D9;
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: right;

      .navigation-in {
        display: flex;

        .btn-link {
          margin-right: 2rem;
          text-decoration: none;
          color: white;
          font-weight: normal;
        }
        .btn-primary {
          margin-right: 1em;
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .header-container .header-in .navigation-mobile {
    display: flex;
    color: #CED1D9;
    flex-grow: 1;
    align-items: center;
    justify-content: right;

    .mobile-menu-toggler {
      cursor: pointer;
      padding: 0.6rem 1rem 0.6rem 0.6rem;

      .mobile-menu-close-btn {
        margin-right: 4px;
      }
    }
  }
  .header-container .header-in .logo {
    width: 120px;
    margin-left: 0.5rem;
    padding-right: 10px;
  }
  .header-container .header-in .title .title-in {
    font-size: 14px;
    padding: 0 0 0 10px;
    height: 24px;
  }

  .header-container .header-in .navigation {
    display: none;
  }
}
