
.spinner-container {
  .spinner-overlay {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;

    .spinner-container-in {
      width: 70px;
      height: 70px;
      border: 8px solid rgba(195, 195, 195, 0.6);
      border-radius: 50%;
      border-top-color: #636767;
      animation: spin 1s ease-in-out infinite;
      -webkit-animation: spin 1s ease-in-out infinite;
      @keyframes spin {
        to {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
      @-webkit-keyframes spin {
        to {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
    }

  }

  .spinner-label {
    width: 100%;
    text-align: center;
  }
}
