@import "src/App";

.footer-container {
  text-align: left;
  width: 100%;
  height: 100%;
  display: inline-block;

  .footer-container-in {
    height: 100%;
    padding: 0 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .footer-top {
      flex-grow: 1;
      padding-top: 4rem;

      .footer-top-left {
        margin-bottom: 1rem;

        .logo {
          width: 123px;
          height: 36px;
        }

        .text {
          max-width: 424px;
          margin-top: 2rem;
        }

        .links {
          margin-top: 3rem;

          a {
            display: inline-block;
            float: left;
            margin-right: 5rem;
            color: #FFFFFF;
            white-space: nowrap;
            margin-top: 0.5rem;

            &.link-about {
              display: none;
            }
          }
        }
      }

      .footer-top-right {
        text-align: right;
        margin-top: 1rem;
        display: flex;
        justify-content: flex-end;

        .social-logo {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 14px;
          gap: 10px;
          width: 46px;
          height: 46px;
          flex: none;
          order: 0;
          flex-grow: 0;

          .svg {
            background: #FFFFFF;
          }
        }

        .facebook-logo {
          padding-left: 18px;
          background: #ED1164;
          margin-right: 1rem;
        }

        .instagram-logo {
          margin-right: 0.6rem;
        }
      }
    }
  }
}

.footer-bottom {
  height: 71px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 1px 0 rgba(54, 59, 71, 0.5);
  background-color: #222222;
}


@include media-breakpoint-down(md) {
  .footer-container {

    .footer-container-in {
      padding: 0 1rem;
    }

    .footer-container-in .footer-top {
      padding-top: 2rem;
    }

    .footer-container-in .footer-top .footer-top-right {
      justify-content: flex-start;
      text-align: left;
      margin-top: 1rem;
    }

    .footer-container-in .footer-top .footer-top-left .links a {
      margin-right: 2rem;

      &.link-about {
        display: inline;
      }
    }
  }
}
