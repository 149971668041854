.search-input-container {
  margin-bottom: 0.5rem;
  display: flex;
  position: relative;

  .input {
    font-size: 14px;
    font-weight: 500;
    margin-right: 5px;
    flex-grow: 1;
    height: 46px;
    margin-top: 1px;
    border-color: #CED1D9 !important;

    &:hover {
      border-color: #CED1D9;
    }
  }

  .clear-button {
    position: absolute;
    right: 54px;
    top: 5px;
    padding: 6px 8px;

    svg {
      -webkit-transition: color 450ms;
      transition: color 450ms;
      color: rgb(199, 198, 198);
      width: 20px;
      height: 20px;
    }

    &:hover {
      svg {
        color: rgb(140, 140, 140);
      }
    }
  }

  .search-button {
    height: 48px;
    width: 48px;
    padding: 10px 14px 13px !important;
  }

  &.search-input-container-desktop {
    .input {
      border-color: #E9EAF0 !important;
    }
  }
}
