@import 'src/App';

.faq-container {
  padding-top: 0.5rem;
  .question {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
  }
  .answer {
    margin-bottom: 2rem;
    text-align: justify;
  }
}

@include media-breakpoint-down(md) {
  .faq-container {
    .question {
      font-size: 22px;
      line-height: 26px;
    }
  }
}
