@import 'src/App';

.book-details-container {
  .book-short-info {
    padding: 24px;
    background: #FFFFFF;
    border: 1px solid #E9EAF0;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
    margin-bottom: 2rem;

    .uploaded {
      color: #6E7485;
      font-weight: 400;
      font-size: 12px;
      line-height: 6px;
      margin-bottom: 1rem;
    }

    .cover-and-title-category-author {
      display: flex;

      .cover {
        width: 171px;
        height: 243px;
        border: 1px solid #f0f2f6;
        margin-right: 30px;

        img {
          width: 171px;
          height: 243px;
          object-fit: contain;
        }
      }

      .title-category-author {
        flex-grow: 1;

        .title {
          font-weight: 500;
          font-size: 28px;
          line-height: 34px;
          margin-bottom: 0.8rem;

          &.title-lang {
            margin-top: 1.5rem;
          }
        }

        .category, .author {
          margin-bottom: 0.5rem;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;

          .label {
            color: #6E7485
          }
        }

        .author {
          margin-bottom: 1.3rem;
        }
      }
    }
  }

  .book-levels-label {
    margin: 3rem 0 2rem;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #222222;
  }

  .book-levels {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;

    .level-link-container {
      a {
        text-decoration: none;
        font-weight: 600;
        font-size: 22px;
        line-height: 21px;
        color: #222222;

        .level {
          padding: 1.2rem 4.8rem;
          background: #E9EAF0;
          border: 1px solid #E9EAF0;
          border-radius: 3.15877px;

          &:hover {
            background-color: #CED1D9 !important;
          }
        }
      }
    }
  }

  .title-translated {
    font-weight: 500;
  }

  .description {
    font-family: 'Lora', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 26px;
    letter-spacing: -0.01em;
    color: #6E7485;
    margin: 1rem 0;
    //display: -webkit-box;
    //-webkit-line-clamp: 4;
    //line-clamp: 4;
    //-webkit-box-orient: vertical;
    overflow: hidden;

    .description-translated {
      margin-bottom: 0;
    }
  }

  .read-book-button-container {
    .read-book-button {
      width: 100%;
    }
  }
}

@include media-breakpoint-down(xl) {
  .book-details-container .book-levels .level-link-container a .level {
    padding: 1.2rem 3rem;
  }
}

@include media-breakpoint-down(lg) {
  .book-details-container .book-levels .level-link-container a .level {
    padding: 1rem 2rem;
  }
}

@include media-breakpoint-down(md) {
  .book-details-container .book-levels .level-link-container a {
    font-size: 18px;
    .level {
      padding: 0.8rem 1rem;
    }
  }

  .book-details-container .book-levels-label {
    font-size: 15px;
    margin: 1rem 0 1rem;
  }

  .book-details-container {
    .book-short-info {
      padding: 19px;

      .uploaded {
        font-weight: 400;
        font-size: 12px;
        line-height: 6px;
        margin-bottom: 1rem;
      }

      .cover-and-title-category-author {
        .cover {
          width: 93px;
          height: 126px;
          margin-right: 20px;

          img {
            width: 93px;
            height: 126px;
          }
        }

        .title-category-author {
          .title {
            font-size: 18px;
            line-height: 24px;
          }
        }
      }

      .title {
        font-size: 18px;
        line-height: 24px;

        &.title-lang {
          margin: 1.5rem 0 0.5rem;
        }
      }

      .title-translated{
        margin-bottom: 1rem;
        font-weight: bold;
        line-height: 1.2rem;
      }
    }

    .description {
      margin-top: 0;
      margin-bottom: 0;

      &.description-mobile {
        margin-top: 1rem;
      }
    }

  }
}
