@import "src/App";

.home-container {

  .header-and-link-to-all {
    display: flex;

    h2 {
      font-weight: 500;
      font-size: 30px;
      line-height: 36px;
      margin-top: 6px;
      margin-bottom: 23px;
      margin-right: auto;

      &.new-releases-header {
        // margin-top: 2.5rem;
      }
    }

    .link-to-all {
      align-self: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.01em;
      display: flex;

      .arrow {
        align-self: center;
        padding-bottom: 2px;
        padding-right: 7px;
      }

      .link {
        align-self: center;

        a {
          text-decoration: none;
          color: #0085FF;
        }
      }
    }
  }

  .search-result-container {
    margin-bottom: 1rem;
  }
}

@include media-breakpoint-down(md) {
  .home-container {

    .header-and-link-to-all {

      h2 {
        font-weight: 500;
        font-size: 22px;
        line-height: 16px;
        margin-top: 15px;
        margin-bottom: 1rem;

        &.new-releases-header {
          margin-top: 1rem;
        }
      }

      .link-to-all {
        padding-bottom: 0px;
      }
    }

    .mobile-splitter {
      border-bottom: 1px solid #CED1D9;
      margin-bottom: 1.5rem;
    }

    .banner {
      display: none;
    }
  }
}
