.search-box-container {
  margin-top: 2rem;
  margin-bottom: 1rem;
  width: 100%;

  .search-input-group {
    margin-bottom: 2rem;

    .label {
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 12px;
    }
  }

  .filter-button {
    width: 100%;
    height: 46px;
    margin-top: 1rem;
  }

  &.search-box-container-desktop {
    margin-top: 0;
    .label {
      padding: 18px 18px 18px 13px;
      border: 1px solid #E9EAF0;
      border-bottom: none;
      background: #FFFFFF;
      margin-bottom: 0;
    }

    .react-select__control {
      border-color: #E9EAF0;
    }

    .search-input-group {
      margin-bottom: 1rem !important;
    }
  }

  .language-select {
      border-top: 1px solid #E9EAF0;;
      .react-select__control {
        display: none;
      }
  }
}
