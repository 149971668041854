html {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Barlow', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: #222222;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;

  background-color: #F6F6F6 !important;
  background-repeat: no-repeat;

  #root {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

* {
  box-sizing: border-box;
}

.non-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.pointer {
  cursor: pointer;
}

.max-width-limiter {
  max-width: 1750px;
}
