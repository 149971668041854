.search-result-container {
  padding: 19px;
  background-color: #FFFFFF;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.07);

  .search-result-container-desktop {

    .cover-and-uploaded-title-category-author {
      display: flex;
      .cover {
        border: 1px solid #f0f2f6;
        margin-right: 30px;
        width: 171px;
        height: 243px;
        img {
          width: 171px;
          height: 243px;
          object-fit: contain;
          cursor: pointer;
        }
      }

      .uploaded-title-description-category-author {
        flex-grow: 1;
        border-bottom: 1px solid #f0f2f6;

        .uploaded {
          color: #6E7485;
          font-weight: 400;
          font-size: 12px;
          line-height: 6px;
          margin-bottom: 1rem;
          margin-top: 0.3rem;

          .label {
            color: #CED1D9
          }
        }

        .title {
          a {
            color: #212529;
            text-decoration: none;
            cursor: pointer;
            font-weight: 500;
            font-size: 28px;
            line-height: 34px;
            margin-bottom: 0.8rem;
          }
        }

        .title-translated {
          font-weight: 500;
        }

        .description {
          font-family: 'Lora', sans-serif;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.01em;
          color: #6E7485;
          margin: 1rem 0 1.6rem 0;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .category, .author {
          margin-bottom: 0.5rem;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          .label {
            color: #6E7485
          }
        }
      }
    }
    .read-book-button-container {
      text-align: right;
      .read-book-button {
        margin-top: 1rem;
      }
    }
  }

  .search-result-container-mobile {
    .uploaded {
      color: #6E7485;
      font-weight: 400;
      font-size: 12px;
      line-height: 6px;
      margin-bottom: 1rem;
    }
    .cover-and-title-category-author {
      display: flex;
      .cover {
        width: 93px;
        height: 126px;
        border: 1px solid #f0f2f6;
        margin-right: 30px;
        img {
          width: 93px;
          height: 126px;
          object-fit: contain;
        }
      }

      .title-category-author {
        flex-grow: 1;

        .title {
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 0.8rem;
        }

        .category, .author {
          margin-bottom: 0.5rem;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          .label {
            color: #6E7485
          }
        }
      }
    }

    .title-translated {
      margin-bottom: 1rem;
      font-weight: 500;
      line-height: 1rem;
    }

    .description {
      font-family: 'Lora', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.01em;
      color: #6E7485;
      margin: 1rem 0;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .read-book-button-container {
      .read-book-button {
        width: 100%;
      }
    }
  }
}
