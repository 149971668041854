.select-container {
  min-height: 46px;

  * {
    border-radius: 0 !important;
    font-size: 14px !important;
  }

  .react-select__placeholder {
    font-weight: 500;
    font-size: 16px;
  }

  .react-select__option--is-selected {
    background-color: white;
    color: black;
  }

  .react-select__option:hover {
    background-color: rgba(0, 209, 255, 0.11) !important;
  }
  .react-select__option--is-focused {
    background-color: white !important;
  }

  .react-select__menu {
    box-shadow: none;
    border: 1px solid #E9EAF0;
    border-top: none;
    margin-top: 0;
    //position: relative !important;
  }

  .react-select__menu-list {
    padding-top: 0;
    padding-bottom: 0;
    max-height: 250px;
  }

  .react-select__option {
    padding: 11px 12px;
    border-bottom: 1px solid #E9EAF0;
    cursor: pointer;
  }

  .react-select__multi-value__remove:hover {
    background-color: #DB3069;

    svg {
      color: white;
    }
  }

  .react-select__indicator {
    svg {
      -webkit-transition: color 450ms;
      transition: color 450ms;
      color: rgb(199, 198, 198);
    }

    &:hover {
      svg {
        color: rgb(140, 140, 140);
      }
    }
  }

  .react-select__placeholder {
    font-size: 14px !important;
    font-weight: 500 !important;
  }
  .react-select__input-container {
    font-size: 14px !important;
    * {
      font-weight: 500 !important;
      font-size: 14px !important;
    }
  }

  &.select-container-desktop {
    .react-select__menu {
      position: relative !important;
    }
    .react-select__menu-list {
      max-height: 500px;
    }
    .react-select__dropdown-indicator {
      display: none;
    }
    .react-select__indicator-separator {
      display: none;
    }
    .react-select__value-container {
      padding-left: 11px;
    }
    .react-select__placeholder {
      color: #e1e1e1;
    }
  }
}
