$theme-colors: (
        "primary": #DB3069,
        "secondary": #222222
);
@import "~bootstrap/scss/bootstrap";

.btn {
  border-radius: 0;
  border: none;
  font-weight: bold;
  padding: 10px 18px; //12px 19px originally
}


.form-control {
  border-radius: 0;

  &:focus {
    //border-color: rgb(255, 221, 209);
    //border: none;
    //box-shadow: 0 0 0 0.2rem rgb(255, 221, 209, 0.75);
    box-shadow: none;
  }
}



