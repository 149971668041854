@import "src/App";

.banner-container {
  text-align: center;

  h1 {
    font-weight: 400;
    font-size: 28px;
    line-height: 26px;;
    margin-bottom: 4rem;
  }

  .banner-container-in {

    .single-banner {
      margin-bottom: 3rem;
    }
  }
}

@include media-breakpoint-down(lg) {
  .banner-container {
    h1 {
      margin-bottom: 3rem;
      font-size: 20px;
      line-height: 18px;
    }
  }
}
