.not-found-container {
  width: 100%;
  text-align: center;
  font-family: 'Barlow', sans-serif;

 .not-found-container-in {
   margin: 5rem auto 5rem auto;
   width: fit-content;
   block-size: fit-content;
   text-align: left;

   .header {
     font-style: normal;
     font-weight: 500;
     font-size: 30px;
     line-height: 36px;
   }

   .home-button {
     display: block;
     padding-top: 2rem;
   }
 }
}
