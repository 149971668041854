.select-item-option-container {
  .select-item-option-container-in {
    cursor: pointer;
    display: flex;
    input {
      cursor: pointer;
      border-color: #CED1D9;
      margin-right: 15px;
      padding: 9px;

      &:checked {
        background-color: #DB3069;
        border-color: #DB3069;
      }
    }

    label {
      cursor: pointer;
      flex-grow: 1;
      padding-top: 4px;
      font-weight: 500;
      font-size: 14px;
      color: #6E7485;
    }

    span.resultCount {
      padding-right: 0.5rem;
      padding-top: 4px;
      color: #CED1D9
    }
  }
}
