@import "src/App";

.search-result-page-container {
  color: #222222;

  .filter-summary-and-sorting {
    display: flex;

    .filter-summary {
      margin: 14px auto 29px 0;
      padding-right: 18px;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.01em;
      .results-count {
        color: #6E7485
      }
    }

    .sorting {
      align-self: center;
      margin-bottom: 1rem;
      min-width: 255px;

      .col {
        align-self: center;
      }
      .col-select {
        min-width: 220px;
      }
    }
  }

  .results {
    .search-result-container {
      margin-bottom: 1rem;
    }
  }
}


@include media-breakpoint-down(md) {
  .search-result-page-container .filter-summary-and-sorting .filter-summary {
    margin-bottom: 10px;
  }
  .search-result-page-container .filter-summary-and-sorting {
    display: block;
  }
}
