@import "src/App";

.slogan-container {
  margin-top: 2rem;
  margin-bottom: 5rem;

  text-align: center;

  h1 {
    font-weight: 500;
    font-size: 60px;
    line-height: 72px;
  }

  h2 {
    font-weight: 400;
    font-size: 45px;
    line-height: 54px;
  }

  .primary-color {
    color: #DB3069;
  }
}

@include media-breakpoint-down(lg) {
  .slogan-container {
    margin-top: 3rem;
    margin-bottom: 2rem;
    h1 {
      font-size: 45px;
      line-height: 52px;
    }

    h2 {
      font-size: 30px;
      line-height: 36px;
    }

  }
}

@include media-breakpoint-down(md) {
  .slogan-container {
    margin-top: 1rem;
    margin-bottom: 1.2rem;
    h1 {
      font-size: 27px;
      line-height: 32px;
    }

    h2 {
      font-size: 20px;
      line-height: 24px;
    }

  }
}
